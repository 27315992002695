'use client';

import { ReactNode, useEffect, useState } from 'react';

import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { useRouter } from 'next/navigation';

type WrappedAuth0ProviderProps = {
  children: ReactNode;
};

const WrappedAuth0Provider: React.FunctionComponent<
  WrappedAuth0ProviderProps
> = ({ children }) => {
  const router = useRouter();
  const [redirectUri, setRedirectUri] = useState<string | null>(null);

  useEffect(() => {
    setRedirectUri(`${window?.location.origin}/auth-callback`);
  }, []);

  const onRedirectCallback = (appState?: AppState) => {
    router.replace(`/login-redirect?redirectTo=${appState?.returnTo || '/'}`);
  };

  return redirectUri ? (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  ) : null;
};

export default WrappedAuth0Provider;
