'use client';

import { ReactNode } from 'react';

import { NuqsAdapter } from 'nuqs/adapters/next/app';

import ApolloProvider from './apollo';
import AuthProvider from './auth';
import StatsigProvider from './statsig';
import { UserProvider } from './user';

export type ProvidersProps = {
  children: ReactNode;
};

const Providers: React.FunctionComponent<ProvidersProps> = ({ children }) => {
  return (
    <NuqsAdapter>
      <AuthProvider>
        <ApolloProvider>
          <StatsigProvider>
            <UserProvider>{children}</UserProvider>
          </StatsigProvider>
        </ApolloProvider>
      </AuthProvider>
    </NuqsAdapter>
  );
};

export default Providers;
